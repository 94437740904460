import { useQuery } from 'react-apollo'
import { useLocation } from 'react-router-dom'
import GET_CONCEPT_LOCATIONS_CONTEXT from 'shared/graphql/queries/getConceptLocationsContext'
import GET_CONCEPTS from 'shared/graphql/queries/getConcepts'
import GET_ELEMENTS from 'pages/menu/graphql/queries/getElements'
import qs from 'query-string'
import get from 'lodash.get'

export const useQueryParams = () => {
  return qs.parse(useLocation().search)
}

export const useConceptLocationsContext = () => {
  const {
    data: {
      activeConcept: { id: activeConceptId },
      locationsContext: { mode, ids }
    }
  } = useQuery(GET_CONCEPT_LOCATIONS_CONTEXT)

  const {
    data: conceptsData,
    error: conceptsError,
    loading: conceptsLoading
  } = useQuery(GET_CONCEPTS)

  if (conceptsError) return { activeConceptId, mode, ids, conceptsError }

  else if (conceptsLoading)
    return { activeConceptId, mode, ids, conceptsLoading }

  return {
    activeConceptId,
    mode,
    ids,
    concepts: conceptsData.concepts
  }
}

// uses `useConceptLocationsContext` to generate a conceptLocationsFilter
export const useConceptLocationsFilter = () => {
  const { activeConceptId, mode, ids } = useConceptLocationsContext()

  if (mode === 'SHOW_ALL') {
    return {
      conceptId: activeConceptId
    }
  } else if (mode === 'EXCLUDE_LOCATIONS') {
    return {
      conceptId: activeConceptId,
      excludeLocations: ids
    }
  } else if (mode === 'SHOW_ONLY') {
    return {
      conceptId: activeConceptId,
      locations: ids
    }
  } else if (mode === 'COHORTS') {
    return {
      conceptId: activeConceptId,
      cohorts: ids
    }
  } 
  else {
    return {
      conceptId: activeConceptId
    }
  }
}

export const useConceptLocations = () => {
  const { activeConceptId, mode, ids } = useConceptLocationsContext()

  const filter = (() => {
    if (mode === 'SHOW_ALL') {
      return {
        conceptId: activeConceptId
      }
    } else if (mode === 'EXCLUDE_LOCATIONS') {
      return {
        conceptId: activeConceptId,
        excludeLocations: ids
      }
    } else if (mode === 'SHOW_ONLY') {
      return {
        conceptId: activeConceptId,
        locations: ids
      }
    } else if (mode === 'COHORTS') {
      return {
        conceptId: activeConceptId,
        cohorts: ids
      }
    } else {
      return {
        conceptId: activeConceptId
      }
    }
  })()

  return { filter, context: { activeConceptId, mode, ids } }
}

export const useElementsQuery = () => {
  const conceptLocationsFilter = useConceptLocationsFilter()

  return useQuery(GET_ELEMENTS, {
    variables: {
      conceptLocationsFilter
    },
    skip: get(conceptLocationsFilter, 'conceptId') === null
  })
}
