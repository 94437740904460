import gql from 'graphql-tag'

export default gql`
  query getMenuLocations($conceptId: ID) {
    menuLocations(conceptId: $conceptId) {
      locationId
      name
      concept {
        id
      }
      state
      timezone
      postalCode
    }
  }
`
