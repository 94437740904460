import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MUITextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SelectedIcon from '@material-ui/icons/FormatListBulleted'
import Tooltip from '@material-ui/core/Tooltip'
import Clear from '@material-ui/icons/Clear'
import ToggleButton from '@material-ui/lab/ToggleButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Formik, FieldArray } from 'formik'
import { contains } from 'utils'
import { decode } from 'utils/graphql'
import { FixedSizeList as List } from 'react-window'
import Space from 'shared/components/Space'
import GET_MENU_LOCATIONS from 'shared/graphql/queries/getMenuLocations'
import COHORTS from 'shared/graphql/queries/cohorts'
import GET_CONCEPT_LOCATIONS_CONTEXT from 'shared/graphql/queries/getConceptLocationsContext'
import SET_LOCATIONS_CONTEXT_SHOW_ONLY from 'shared/graphql/mutations/setLocationsContextShowOnly'
import SET_LOCATIONS_CONTEXT_COHORTS from 'shared/graphql/mutations/setLocationsContextCohorts'
import SET_LOCATIONS_CONTEXT_SHOW_ALL from 'shared/graphql/mutations/setLocationsContextShowAll'
import SET_LOCATIONS_CONTEXT_EXCLUDE_LOCATIONS from 'shared/graphql/mutations/setLocationsContextExcludeLocations'
import Fuse from 'fuse.js'

const fuzzyConfig = {
  shouldSort: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['decodedId', 'name'],
  threshold: 0.2
}

const fuzzyFind = (val, coll) => {
  const inputValue = val.trim().toLowerCase()
  const fuse = new Fuse(coll, fuzzyConfig)

  if (inputValue.length === 0) return []

  return fuse.search(inputValue)
}

const SelectLocations = ({
  classes,
  closeSelectLocations,
  concept,
  locationsContext: { mode, ids },
  cohorts,
  cohortsLoading,
  cohortsError,
  menuLocations,
  menuLocationsLoading,
  menuLocationsError,
  setLocationsContextShowAll,
  setLocationsContextShowOnly,
  setLocationsContextCohorts,
  setLocationsContextExcludeLocations
}) => {
  const [inputValue, setInputValue] = useState('')
  const [onlyShowSelected, setOnlyShowSelected] = useState(false)
  const toggleOnlyShowSelected = () => setOnlyShowSelected(!onlyShowSelected)

  if (menuLocationsError) throw new Error(menuLocationsError)
  if (cohortsError) throw new Error(cohortsError)

  if (menuLocationsLoading || cohortsLoading) {
    return (
      <Dialog
        classes={{
          paper: classes.paper,
          root: classes.dialogRoot,
          scrollPaper: classes.scrollPaper
        }}
        open={true}
        onClose={closeSelectLocations}
      >
        <DialogContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="body1" align="center" style={{ flex: 1 }}>
            loading locations...
          </Typography>
        </DialogContent>
      </Dialog>
    )
  }

  menuLocations &&
    menuLocations
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }

        return 0
      })
      .map(l => (l.decodedId = decode(l.locationId).id))

  return (
    <Dialog
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
      open={true}
      onClose={closeSelectLocations}
    >
      <DialogTitle>Filter Locations for {concept.name}</DialogTitle>

      <DialogContent style={{ paddingTop: 0 }}>
        <Formik
          displayName="SelectLocations"
          initialValues={{
            mode,
            ids: ids === null ? [] : ids
          }}
          onSubmit={(values, actions) => {
            if (values.mode === 'SHOW_ONLY') {
              setLocationsContextShowOnly(values.ids).then(() =>
                closeSelectLocations()
              )
            } else if (values.mode === 'EXCLUDE_LOCATIONS') {
              setLocationsContextExcludeLocations(values.ids).then(() =>
                closeSelectLocations()
              )
            } else if (values.mode === 'SHOW_ALL') {
              if (mode === 'SHOW_ALL') {
                console.log('no change, closing filters')
                closeSelectLocations()
              } else if (mode !== 'SHOW_ALL') {
                setLocationsContextShowAll().then(() => closeSelectLocations())
              }
            } else if (values.mode === 'COHORTS') {
              setLocationsContextCohorts(values.ids).then(() =>
                closeSelectLocations()
              )
            }
          }}
          render={({
            handleSubmit,
            handleChange,
            values,
            isSubmitting,
            setFieldValue
          }) => {
            const filtered = (() => {
              if (onlyShowSelected)
                return menuLocations.filter(l =>
                  contains(values.ids, l.locationId)
                )
              else if (inputValue.trim().length === 0) return menuLocations
              else return fuzzyFind(inputValue, menuLocations)
            })()

            return (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  <RadioGroup
                    aria-label="Filter Type"
                    classes={{
                      root: classes.radioGroupRoot
                    }}
                    name="mode"
                    value={values.mode}
                    onChange={e => {
                      if (
                        values.mode === 'SHOW_ONLY' &&
                        e.target.value !== 'EXCLUDE_LOCATIONS'
                      )
                        setFieldValue('ids', [])
                      if (
                        values.mode === 'EXCLUDE_LOCATIONS' &&
                        e.target.value !== 'SHOW_ONLY'
                      )
                        setFieldValue('ids', [])
                      if (
                        values.mode === 'COHORTS' &&
                        e.target.value !== 'COHORTS'
                      )
                        setFieldValue('ids', [])
                      handleChange(e)
                    }}
                  >
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={
                        <Radio
                          classes={{
                            checked: classes.radioChecked,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      label="All"
                    />

                    <FormControlLabel
                      value="SHOW_ONLY"
                      control={
                        <Radio
                          classes={{
                            checked: classes.radioChecked,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      label="Only"
                    />

                    <FormControlLabel
                      value="EXCLUDE_LOCATIONS"
                      control={
                        <Radio
                          classes={{
                            checked: classes.radioChecked,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      label="Exclude"
                    />

                    <FormControlLabel
                      value="COHORTS"
                      control={
                        <Radio
                          classes={{
                            checked: classes.radioChecked,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      label="Cohorts"
                    />
                  </RadioGroup>

                  <div
                    style={{
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      zIndex: 1
                    }}
                  >
                    <Button onClick={closeSelectLocations}>cancel</Button>

                    <Space direction="x" value="quarter" />

                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        (values.mode !== 'SHOW_ALL' && values.ids.length < 1) ||
                        isSubmitting
                      }
                    >
                      apply
                    </Button>
                  </div>
                </div>

                {values.mode === 'COHORTS' && (
                  <FieldArray
                    name="ids"
                    render={arrayHelpers => {
                      const cohortOptions = cohorts.map(c => {
                        const activeCohort = values.ids.indexOf(c.id) > -1
                        const indexOfActiveCohort = values.ids.indexOf(c.id)

                        return (
                          <FormControlLabel
                            key={c.id}
                            label={`${c.name} [${c.type}]`}
                            onChange={() => {
                              if (activeCohort) {
                                arrayHelpers.remove(indexOfActiveCohort)
                              } else arrayHelpers.push(c.id)
                            }}
                            value={c.id}
                            control={
                              <Checkbox
                                classes={{
                                  checked: classes.checkBoxChecked,
                                  root: classes.checkBoxRoot
                                }}
                                checked={activeCohort}
                                disabled={values.mode === 'SHOW_ALL'}
                              />
                            }
                          />
                        )
                      })

                      return (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {cohortOptions}
                        </div>
                      )
                    }}
                  />
                )}

                {values.mode !== 'SHOW_ALL' && values.mode !== 'COHORTS' && (
                  <>
                    <Space direction="y" value="one" />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MUITextField
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={inputValue.length === 0}
                                onClick={() => setInputValue('')}
                              >
                                <Tooltip title="clear">
                                  <Clear />
                                </Tooltip>
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        label="find location"
                        placeholder="search locations by name or ID"
                        variant="outlined"
                        value={inputValue}
                        onFocus={() => setOnlyShowSelected(false)}
                        onChange={e => setInputValue(e.target.value)}
                      />

                      <Space direction="x" value="half" />

                      <ToggleButton
                        selected={onlyShowSelected}
                        value="show selected"
                        onClick={() => {
                          toggleOnlyShowSelected()
                          setInputValue('')
                        }}
                      >
                        <Tooltip
                          title={(() => {
                            if (onlyShowSelected) return 'show all'
                            else if (onlyShowSelected === false) {
                              if (values.mode === 'SHOW_ONLY')
                                return 'show selected'
                              if (values.mode === 'EXCLUDE_LOCATIONS')
                                return 'show excluded'
                            }
                          })()}
                        >
                          <SelectedIcon />
                        </Tooltip>
                      </ToggleButton>
                    </div>

                    <FieldArray
                      name="ids"
                      render={arrayHelpers => {
                        return (
                          <List
                            height={320}
                            style={{
                              borderRadius: '4px',
                              overscrollBehavior: 'contain',
                              paddingLeft: 8
                            }}
                            itemCount={filtered.length}
                            itemSize={64}
                          >
                            {({ index, style }) => {
                              const l = filtered[index]

                              return (
                                <FormControlLabel
                                  key={l.locationId}
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={contains(
                                        values.ids,
                                        l.locationId
                                      )}
                                      onChange={event => {
                                        event.target.checked
                                          ? arrayHelpers.push(l.locationId)
                                          : arrayHelpers.remove(
                                              values.ids.indexOf(l.locationId)
                                            )
                                      }}
                                    />
                                  }
                                  label={`${l.name} - #${
                                    decode(l.locationId).id
                                  }`}
                                  style={{ display: 'flex', ...style }}
                                />
                              )
                            }}
                          </List>
                        )
                      }}
                    />
                  </>
                )}
              </form>
            )
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

const muiStyles = theme => ({
  dialogRoot: {
    paddingTop: 96
  },
  checkBoxChecked: {
    color: [['var(--novo-green)'], '!important']
  },
  checkBoxRoot: {
    zIndex: 0
  },
  paper: {
    minHeight: 140,
    width: 600
  },
  radioChecked: {
    color: [['var(--novo-green)'], '!important']
  },
  radioRoot: {
    backgroundColor: 'white'
  },
  radioGroupRoot: {
    backgroundColor: 'white',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
})

export default compose(
  graphql(COHORTS, {
    props: ({ data: { error, loading, cohorts } }) => {
      if (error) return { cohortsError: error }
      if (loading) return { cohortsLoading: loading }

      return {
        cohortsLoading: false,
        cohorts
      }
    }
  }),
  graphql(GET_CONCEPT_LOCATIONS_CONTEXT, {
    props: ({ data: { error, loading, activeConcept, locationsContext } }) => {
      if (error) return { conceptLocationsContextError: error }
      if (loading) return { conceptLocationsContextLoading: loading }

      return {
        activeConcept,
        conceptLocationsContextLoading: false,
        locationsContext
      }
    }
  }),
  graphql(SET_LOCATIONS_CONTEXT_SHOW_ALL, {
    name: 'setLocationsContextShowAll',
    props: ({ setLocationsContextShowAll }) => ({
      setLocationsContextShowAll: () => setLocationsContextShowAll()
    })
  }),
  graphql(SET_LOCATIONS_CONTEXT_SHOW_ONLY, {
    name: 'setLocationsContextShowOnly',
    props: ({ setLocationsContextShowOnly }) => ({
      setLocationsContextShowOnly: ids =>
        setLocationsContextShowOnly({ variables: { ids } })
    })
  }),
  graphql(SET_LOCATIONS_CONTEXT_COHORTS, {
    name: 'setLocationsContextCohorts',
    props: ({ setLocationsContextCohorts }) => ({
      setLocationsContextCohorts: ids =>
        setLocationsContextCohorts({ variables: { ids } })
    })
  }),
  graphql(SET_LOCATIONS_CONTEXT_EXCLUDE_LOCATIONS, {
    name: 'setLocationsContextExcludeLocations',
    props: ({ setLocationsContextExcludeLocations }) => ({
      setLocationsContextExcludeLocations: ids =>
        setLocationsContextExcludeLocations({ variables: { ids } })
    })
  }),
  graphql(GET_MENU_LOCATIONS, {
    props: ({ data: { error, loading, menuLocations } }) => {
      if (error) return { menuLocationsError: error }
      if (loading) return { menuLocationsLoading: loading }

      return {
        menuLocationsLoading: false,
        menuLocations
      }
    },
    options: ownProps => ({
      variables: { conceptId: ownProps.activeConcept.id }
    }),
    skip: ownProps =>
      !ownProps.activeConcept.id || ownProps.activeConcept.id === null
  }),
  withStyles(muiStyles, { withTheme: true })
)(SelectLocations)
