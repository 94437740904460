import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link).withConfig({ displayName: 'StyledLink' })`
  color: inherit;
  font: inherit;
  height: 100%;
  margin: 0;
  width: 100%;
`

export default StyledLink
