import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import { Formik } from 'formik'
import * as yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import { Base64 } from 'js-base64'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { format, parseISO } from 'date-fns'
import StyledLink from 'shared/components/StyledLink'
import Space from 'shared/components/Space'
import GET_PHONE_CALL_BASICS from 'pages/transcript/queries/getPhoneCallBasics'

// GlobalSearch allows users to search for resources across all Concepts, eg: phone calls
const GlobalSearch = props => {
  const { classes } = props
  const [searchValue, setSearchValue] = useState(null)
  const [showResults, setShowResults] = useState(false)
  const anchorElement = useRef(null)

  const handleClose = event => {
    setShowResults(false)
  }

  // if value entered is a UUID, formik will call setSearchValue with the
  return (
    <GlobalSearchRoot>
      <Formik
        displayName="Global Search"
        initialValues={{
          convoId: ''
        }}
        onSubmit={(values, actions) => {
          setSearchValue(values.convoId.replace(/ /g, ''))
          actions.setSubmitting(false)
        }}
        validationSchema={yup.object().shape({
          convoId: yup
            .string()
            .matches(
              /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
              'Please enter a valid UUID'
            )
        })}
      >
        {({
          dirty,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'inline-flex'
                }}
              >
                {errors.convoId && touched.convoId && (
                  <p style={{ color: 'crimson', fontSize: '.75rem' }}>
                    {errors.convoId}
                  </p>
                )}

                <Space value="half" direction="x" display="inline-block" />

                <TextField
                  inputRef={node => (anchorElement.current = node)}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot
                    }
                  }}
                  name="convoId"
                  value={values.convoId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="find phone call by convo id"
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Space direction="x" value="half" display="inline-block" />

                  <Button
                    disabled={
                      !dirty || (errors.convoId && errors.convoId !== undefined)
                    }
                    type="submit"
                    onClick={() => setShowResults(!showResults)}
                  >
                    go
                  </Button>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>

      <Query
        displayName="callByConvoId"
        query={GET_PHONE_CALL_BASICS}
        variables={{
          convoId: Base64.encode(`Conversation:${searchValue}`)
        }}
        skip={searchValue === null}
      >
        {({ loading, error, data }) => {
          if (error) {
            return (
              <Popper
                anchorEl={anchorElement.current}
                disablePortal
                open={showResults}
                placement="bottom-end"
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper
                      classes={{
                        root: classes.paperRoot
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <p
                          style={{
                            color: 'crimson',
                            textAlign: 'center',
                            width: '100%'
                          }}
                        >
                          error while fetching call...
                        </p>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )
          }

          if (loading || !data) {
            return (
              <Popper
                anchorEl={anchorElement.current}
                disablePortal
                open={showResults}
                placement="bottom-end"
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper
                      classes={{
                        root: classes.paperRoot
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center'
                          }}
                        >
                          <CircularProgress color="primary" size={24} />
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )
          }

          if (data && data.call) {
            const { call } = data

            const fromParsed = (() => {
              try {
                const parsed = parsePhoneNumberFromString(call.from, 'US')
                return parsed.formatNational()
              } catch {
                return call.from
              }
            })()

            return (
              <Popper
                anchorEl={anchorElement.current}
                disablePortal
                open={showResults}
                placement="bottom-end"
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper
                      classes={{
                        root: classes.paperRoot
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <List style={{ width: '100%' }}>
                          <StyledLink
                            to={`/c/${call.concept.id}/console/phone calls?id=${
                              call.convoId
                            }`}
                          >
                            <ListItem button onClick={handleClose}>
                              <ListItemText
                                primary={
                                  <>
                                    <Typography
                                      variant="subtitle1"
                                      color="primary"
                                    >
                                      {fromParsed}
                                    </Typography>

                                    <Typography
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {call.location.name}
                                    </Typography>
                                  </>
                                }
                                secondary={
                                  <Typography variant="body1">
                                    {format(
                                      parseISO(call.answered),
                                      'MMMM dd, yyyy h:mm a'
                                    )}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </StyledLink>
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )
          }

          return null
        }}
      </Query>
    </GlobalSearchRoot>
  )
}

const GlobalSearchRoot = styled.div.withConfig({ displayName: 'GlobalSearch' })`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

const muiStyles = theme => ({
  paperRoot: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    minWidth: 350,
    minHeight: 66
  },
  inputRoot: {
    width: 375
  }
})

export default withStyles(muiStyles, { withTheme: true })(GlobalSearch)
