import { Base64 } from 'js-base64'

export const decode = encoded => {
  try {
    const decoded = Base64.decode(encoded).split(':')
    return { object: decoded[0], id: decoded[1] }
  } catch (error) {
    console.warn('error while decoding: ', encoded, `\n ${error}`)
    return encoded
  }
}

export const encode = (id, type) => {
  return Base64.encode(`${type}:${id}`)
}
