import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import client from 'config/ApolloClient'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { NovoMainTheme } from 'themes'
import App from 'App'
import GlobalStyle from 'shared/GlobalStyle'

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <ThemeProvider theme={NovoMainTheme}>
        <Router>
          <SCThemeProvider theme={{}}>
            <React.Fragment>
              <App />
              <GlobalStyle />
            </React.Fragment>
          </SCThemeProvider>
        </Router>
      </ThemeProvider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root')
)
