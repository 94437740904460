import gql from 'graphql-tag'

export default gql`
  query elements($conceptLocationsFilter: ConceptLocationsFilter) {
    elements(conceptLocationsFilter: $conceptLocationsFilter) {
      category {
        categoryId
        displayName
        lookupName
      }
      displayName
      displayPlural
      elementId
      elementType
      lookupName
      price
    }
  }
`
