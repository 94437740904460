import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { breakPoints } from 'shared/breakPoints'
import AppBar from '@material-ui/core/AppBar'
import GlobalSearch from 'shared/MainAppBar/GlobalSearch'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import NovoLogoFull from 'shared/components/NovoLogoFull'

class MainAppBar extends Component {
  state = {
    searchValue: ''
  }

  render() {
    const { classes, env } = this.props

    return (
      <AppBar
        classes={{
          root: classes.appBarRoot,
          colorPrimary: classes.appBarColorPrimary
        }}
      >
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ResponsiveSpacer />

            <ResponsiveLogo />

            <Typography
              variant="subtitle1"
              style={{
                color: 'var(--novo-dark-gray)',
                textShadow: '-4px 2px rgba(187, 187, 187, .6)',
                marginLeft: 8
              }}
            >
              <em>{env ? env.toUpperCase() : ''}</em>
            </Typography>
          </div>

          <GlobalSearch />
        </Toolbar>
      </AppBar>
    )
  }
}

const drawerWidth = 265

const ResponsiveLogo = styled(NovoLogoFull).withConfig({
  displayName: 'ResponsiveLogo'
})`
  height: 36px;

  @media ${breakPoints.muiLarge} {
    height: 36px;
  }
`

const ResponsiveSpacer = styled.div.withConfig({
  displayName: 'responsiveSpacer'
})`
  width: 36px;

  @media ${breakPoints.muiLarge} {
    display: none;
  }
`

const muiStyles = theme => ({
  appBarRoot: {
    top: 0,
    marginLeft: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  appBarColorPrimary: {
    backgroundColor: 'white'
  },
  textFieldStyles: {
    width: 261,
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
})

export default withStyles(muiStyles, { withTheme: true })(MainAppBar)
