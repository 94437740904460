// non-production assumes you are running docker-compose.
// production is proxied via an nginx configuration
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { mutations, defaultState } from 'config/client-state'

const graphqlUri =
  process.env.NODE_ENV === 'production'
    ? '/graphql'
    : 'http://localhost:9000/graphql'

const cache = new InMemoryCache()

cache.writeData({
  data: defaultState
})

const client = new ApolloClient({
  cache,
  link: new HttpLink({ uri: graphqlUri }),
  resolvers: { Mutation: mutations }
})

export default client
