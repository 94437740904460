import gql from 'graphql-tag'

export default gql`
  query getConceptLocationsContext {
    activeConcept @client {
      id
    }

    locationsContext @client {
      mode
      ids
    }
  }
`
