export const defaultState = {
  activeCall: {
    __typename: 'ActiveCall',
    id: null,
    convoId: null
  },
  activeConcept: {
    __typename: 'ActiveConcept',
    id: null
  },
  locationsContext: {
    __typename: 'LocationsContext',
    mode: 'SHOW_ALL',
    ids: null
  }
}

export const mutations = {
  setLocationsContextCohorts: (_, { ids }, { cache }) => {
    const data = {
      locationsContext: {
        __typename: 'LocationsContext',
        mode: 'COHORTS',
        ids
      }
    }

    cache.writeData({ data })

    return null
  },
  setLocationsContextShowAll: (_, __, { cache }) => {
    const data = {
      locationsContext: {
        __typename: 'LocationsContext',
        mode: 'SHOW_ALL',
        ids: null
      }
    }

    cache.writeData({ data })

    return null
  },
  setLocationsContextShowOnly: (_, { ids }, { cache }) => {
    const data = {
      locationsContext: {
        __typename: 'LocationsContext',
        mode: 'SHOW_ONLY',
        ids
      }
    }

    cache.writeData({ data })

    return null
  },
  setLocationsContextExcludeLocations: (_, { ids }, { cache }) => {
    const data = {
      locationsContext: {
        __typename: 'LocationsContext',
        mode: 'EXCLUDE_LOCATIONS',
        ids
      }
    }

    cache.writeData({ data })

    return null
  },
  updateActiveConcept: (_, { newConceptId }, { cache }) => {
    const data = {
      activeConcept: {
        __typename: 'ActiveConcept',
        id: newConceptId === 'null' ? null : newConceptId
      },
      locationsContext: {
        __typename: 'LocationsContext',
        mode: 'SHOW_ALL',
        ids: null
      }
    }

    cache.writeData({ data })

    return null
  },
  updateActiveCall: (_, { newCallId, newConvoId }, { cache }) => {
    const data = {
      activeCall: {
        __typename: 'ActiveCall',
        id: newCallId,
        convoId: newConvoId
      }
    }

    cache.writeData({ data })

    return null
  }
}
