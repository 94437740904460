import { createMuiTheme } from '@material-ui/core/styles'

export const NovoMainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#00BC70'
    },
    secondary: {
      main: '#5594C8',
      contrastText: '#636569'
    },
    text: {
      primary: '#636569',
      secondary: '#9B9B9B'
    }
  },
  status: {
    danger: '#D0021B'
  },
  typography: {
    fontFamily: 'inherit'
  }
})

// create a dark theme?
