import { createGlobalStyle } from 'styled-components'
import PangramRegular from 'fonts/Pangram-Regular.otf'
import PangramBold from 'fonts/Pangram-Bold.otf'
import PangramExtraBold from 'fonts/Pangram-ExtraBold.otf'
import PangramExtraLight from 'fonts/Pangram-ExtraLight.otf'
import PangramLight from 'fonts/Pangram-Light.otf'
import PangramMedium from 'fonts/Pangram-Medium.otf'
import PangramBlack from 'fonts/Pangram-Black.otf'

const GlobalStyle = createGlobalStyle`
 :root {
    --color-utility-warning: rgba(242, 182, 0, 1.0);
    --color-utility-critical: rgba(232, 28, 0, 1.0);

    --novo-green: #00BC70;
    --novo-dark-gray: #636569;
    --novo-light-gray: #BBB;

    --space-eighth: 2px;
    --space-quarter: 4px;
    --space-half: 8px;
    --space-one: 16px;
    --space-one-and-half: 24px;
    --space-two: 32px;
    --space-three: 48px;
    --space-four: 64px;
  }

  @font-face {
    font-family: 'Pangram Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Regular'), url(${PangramRegular});
  }

  @font-face {
    font-family: 'Pangram Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Bold'), url(${PangramBold});
  }

  @font-face {
    font-family: 'Pangram Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Extra Bold'), url(${PangramExtraBold});
  }

  @font-face {
    font-family: 'Pangram Extra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Extra Light'), url(${PangramExtraLight});
  }

  @font-face {
    font-family: 'Pangram Light';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Light'), url(${PangramLight});
  }

  @font-face {
    font-family: 'Pangram Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Medium'), url(${PangramMedium});
  }

  @font-face {
    font-family: 'Pangram Black';
    font-style: normal;
    font-weight: normal;
    src: local('Pangram Black'), url(${PangramBlack});
  }
  
  * {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  
  html {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body, * {
    box-sizing: border-box;
  }


  body {
    color: var(--novo-dark-gray);
    display: flex;
    font: 16px "Pangram Regular", sans-serif;
    margin: 0;
    min-height: 100%;
  }

  #root {
    flex: 1;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }
`
export default GlobalStyle
