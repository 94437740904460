import gql from 'graphql-tag'

export default gql`
  query getPhoneCallBasics($id: ID, $convoId: ID) {
    call: phoneCall(id: $id, convoId: $convoId) {
      convoId
      concept {
        id
      }
      location {
        locationId
        name
      }
      answered
      from
    }
  }
`
