import React, { Component } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Space from 'shared/components/Space'

/**
 * Error boundaries are used to catch JS errors anywhere in their child component tree, log those errors, and display a
 * fallback UI.
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo })
  }

  render() {
    const { level } = this.props

    if (this.state.hasError) {
      return (
        <ErrorBoundaryRoot>
          <Space value="four" />

          <Typography variant="subtitle1">
            Uh oh...something went wrong
          </Typography>

          <Space value="one" />

          <details style={{ whiteSpace: 'pre-wrap' }}>
            <div style={{ paddingLeft: 8, paddingTop: 8 }}>
              <label>Caught In Component: </label> {level ? level : ''}
              <br />
              <br />
              {this.state.error && this.state.error.toString()}
              <br />
              <br />
              <label>Component Stack: </label>
              {this.state.errorInfo.componentStack}
            </div>
          </details>
        </ErrorBoundaryRoot>
      )
    }

    // if no errors, render children
    return this.props.children
  }
}

const ErrorBoundaryRoot = styled.div.withConfig({
  displayName: 'ErrorBounary'
})`
  background-color: rgba(232, 28, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
`

export default ErrorBoundary
