import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isEmpty } from 'utils'
import { useConceptLocationsContext } from 'shared/hooks'

// Routes that are dependent on an active concept
export const ConceptRoute = ({ children, render, ...rest }) => {
  const { activeConceptId, concepts } = useConceptLocationsContext()

  let activeConcept

  if (!concepts) return null

  if (concepts) {
    activeConcept = concepts.find(c => c.id === activeConceptId)
  }

  return (
    <Route
      {...rest}
      render={
        isEmpty(activeConcept)
          ? () => <Redirect to="/c/null/console/phone calls" />
          : render
      }
    />
  )
}
