import gql from 'graphql-tag'

export default gql`
  mutation updateActiveConcept($newConceptId: ID!, $newConceptName: String!) {
    updateActiveConcept(
      newConceptId: $newConceptId
      newConceptName: $newConceptName
    ) @client
  }
`
