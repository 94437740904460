import React, { Component, Suspense } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { ConceptRoute } from 'shared/components/navigation'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import { breakPoints } from 'shared/breakPoints'
import { MdMenu } from 'react-icons/md'
import ErrorBoundary from 'shared/components/ErrorBoundary'
import GET_CONCEPTS from 'shared/graphql/queries/getConcepts'
import MainAppBar from 'shared/MainAppBar'
import ConceptSelect from 'shared/components/ConceptSelect'
import LocationNavigator from 'shared/LocationNavigator'

const TranscriptConsole = React.lazy(() =>
  import('pages/transcript/TranscriptConsole')
)

/**
 * App is a top level component wrapped in ApolloProvider, MuiThemeProvider, and Router.
 */
class App extends Component {
  state = {
    mobileOpen: false,
    environment: ''
  }

  componentDidMount() {
    if (window) {
      if (window.location.href.indexOf('localhost') !== -1) {
        this.setState({ environment: 'development' })
        return null
      }

      if (window.location.href.indexOf('novolabs.com') !== -1) {
        this.setState({ environment: 'production' })
        return null
      }

      if (window.location.href.indexOf('novolabs.tech') !== -1) {
        this.setState({ environment: 'canary' })
        return null
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }))
  }

  render() {
    const { classes, concepts, conceptsError } = this.props
    const { mobileOpen, environment } = this.state

    const fabClassName = classNames(
      classes.fab,
      mobileOpen ? classes.fabMoveRight : classes.fabMoveLeft
    )

    // if concepts fail to resolve...
    if (conceptsError) {
      return (
        <AppRoot style={{ backgroundColor: 'var(--novo-dark-gray)' }}>
          <ErrorBoundary level="App.js">
            <MainAppBar env={environment} />
          </ErrorBoundary>

          <MainContent>
            <h3 style={{ color: 'white' }}>
              Could not load concepts...please check server.
            </h3>
          </MainContent>
        </AppRoot>
      )
    }

    return (
      <AppRoot>
        <ErrorBoundary level="App.js">
          <MainAppBar env={environment} />
        </ErrorBoundary>

        <Hidden mdDown implementation="css">
          <Route path="/c/:conceptId/:app/:section" component={ConceptSelect} />

          <Route
            path="/c/:conceptId/:app/:section"
            render={props => (
              <LocationNavigator concepts={concepts} {...props} />
            )}
          />
        </Hidden>

        <HideOnDesktop>
          <Fab
            onClick={this.handleDrawerToggle}
            className={fabClassName}
            color="primary"
            variant="round"
            style={{ height: 36, width: 36 }}
          >
            <MdMenu color="white" />
          </Fab>
        </HideOnDesktop>

        <Hidden lgUp>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              BackdropProps: {
                open: false
              },
              keepMounted: true
            }}
          >
            <Route path="/c/:conceptId/:app/:section" component={ConceptSelect} />

            <Route
              path="/c/:conceptId/:app/:section"
              render={props => (
                <LocationNavigator
                  concepts={concepts}
                  handleDrawerToggle={this.handleDrawerToggle}
                  {...props}
                />
              )}
            />
          </Drawer>
        </Hidden>

        <MainContent>
          <Suspense fallback={<div />}>
            <Switch>
              <ConceptRoute
                path="/c/:conceptId/console/:section"
                render={props => <TranscriptConsole key={props.match.params.conceptId} {...props} />}
              />
              {/**
               * If user tries to load an undefined route, send them back to the landing page.
               */}
              <Route render={() => <Redirect to="/c/null/console/phone calls" />} />
            </Switch>
          </Suspense>
        </MainContent>
      </AppRoot>
    )
  }
}

const AppRoot = styled.div.withConfig({ displayName: 'App' })`
  background-color: #fafafa;
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
  z-index: 1;
`

const HideOnDesktop = styled.div.withConfig({ displayName: 'HideOnDesktop' })`
  @media ${breakPoints.muiLarge} {
    display: none;
  }
`

// on desktop, push main content to the right 265px
const MainContent = styled.main.withConfig({ displayName: 'MainContent' })`
  background-color: rgba(187, 187, 187, 0.2);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  @media ${breakPoints.desktop} {
    margin-left: 265px;
  }
`

const styles = theme => ({
  drawerPaper: {
    width: 265,
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  fab: {
    position: 'fixed',
    left: 16,
    top: 12,
    zIndex: 1501,
    [theme.breakpoints.up('sm')]: {
      top: 16
    }
  },
  fabMoveRight: {
    transform: 'translate3d(265px, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut
    })
  },
  fabMoveLeft: {
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    })
  }
})

export default compose(
  graphql(GET_CONCEPTS, {
    props: ({ data: { loading, error, concepts } }) => {
      if (loading) return { conceptsLoading: loading }
      if (error) return { conceptsError: error }

      return {
        conceptsLoading: false,
        concepts
      }
    }
  }),
  withRouter,
  withStyles(styles, { withTheme: true })
)(App)
