import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { Base64 } from 'js-base64'
import ConceptTile from 'shared/components/ConceptTile'
import GET_CONCEPTS from 'shared/graphql/queries/getConcepts'
import GET_ACTIVE_CONCEPT from 'shared/graphql/queries/getActiveConcept'
import UPDATE_ACTIVE_CONCEPT from 'shared/graphql/mutations/updateActiveConcept'

/**
 * ConceptSelect allows a user to select a concept to view and edit information by Location.
 */
class ConceptSelect extends Component {
  componentDidMount() {
    this.props.updateActiveConcept(this.props.match.params.conceptId)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.conceptId !== this.props.match.params.conceptId
    ) {
      this.props.updateActiveConcept(this.props.match.params.conceptId)
    }
  }

  render() {
    const {
      concepts,
      conceptsLoading,
      conceptsError,
      activeConcept,
      activeConceptLoading,
      activeConceptError
    } = this.props

    if (conceptsLoading || activeConceptLoading) {
      return (
        <ConceptSelectRoot>
          <p>loading spinner</p>
        </ConceptSelectRoot>
      )
    }

    if (conceptsError || activeConceptError) {
      return (
        <ConceptSelectRoot>
          <p>server error...</p>
        </ConceptSelectRoot>
      )
    }

    const conceptTiles =
      concepts &&
      concepts.map(c => {
        if (c.id === activeConcept.id) {
          return (
            <React.Fragment key={c.id}>
              <ConceptTile active id={c.id} key={c.id} name={c.name} match={this.props.match.params} />
              <p style={{ color: 'white', margin: 0, fontSize: '.75rem' }}>
                {Base64.decode(c.id).replace('Concept:', '#')}
              </p>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={c.id}>
            <ConceptTile id={c.id} name={c.name} match={this.props.match.params} />
            <p style={{ color: 'white', margin: 0, fontSize: '.75rem' }}>
              {Base64.decode(c.id).replace('Concept:', '#')}
            </p>
          </React.Fragment>
        )
      })

    return <ConceptSelectRoot>{conceptTiles}</ConceptSelectRoot>
  }
}

/**
 * ConceptSelect only visible on Desktop screen sizes and above.
 */
const ConceptSelectRoot = styled.nav.withConfig({
  displayName: 'ConceptSelectRoot'
})`
  align-items: center;
  background-color: #303e4d;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 24px 0px;
  position: fixed;
  width: 80px;

  div:first-child {
    margin-top: 0px;
  }
`

/**
 * BeforeMount ConceptSelect will need to set activeConceptId to some ID.
 */
export default compose(
  graphql(GET_CONCEPTS, {
    props: ({ data: { loading, error, concepts } }) => {
      if (loading) return { conceptsLoading: loading }
      if (error) return { conceptsError: error }

      return {
        conceptsLoading: false,
        concepts
      }
    }
  }),
  graphql(GET_ACTIVE_CONCEPT, {
    props: ({ data: { error, loading, activeConcept } }) => {
      if (loading) return { activeConceptLoading: loading }
      if (error) return { activeConceptError: error }

      return {
        activeConceptLoading: false,
        activeConcept
      }
    }
  }),
  graphql(UPDATE_ACTIVE_CONCEPT, {
    name: 'updateActiveConcept',
    props: ({ updateActiveConcept }) => ({
      updateActiveConcept: newConceptId =>
        updateActiveConcept({ variables: { newConceptId } })
    })
  })
)(ConceptSelect)
